//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/animation/variables";
@import "@material/theme/variables";

$mdc-chip-fill-color-default: mix(mdc-theme-prop-value(on-surface), mdc-theme-prop-value(surface), 12%) !default;
$mdc-chip-ink-color-default: rgba(mdc-theme-prop-value(on-surface), .87) !default;
$mdc-chip-horizontal-padding: 12px !default;
$mdc-chip-vertical-padding: 7px !default;
$mdc-chip-height-default: 32px !default;

$mdc-chip-icon-color: mdc-theme-prop-value(on-surface) !default;
$mdc-chip-icon-opacity: .54 !default;
$mdc-chip-trailing-icon-hover-opacity: .62 !default;
$mdc-chip-trailing-icon-focus-opacity: .87 !default;
$mdc-chip-leading-icon-size: 20px !default;
$mdc-chip-trailing-icon-size: 18px !default;
// Speed up delay to bridge gap between leading icon and checkmark transition.
$mdc-chip-leading-icon-delay: -50ms !default;
$mdc-chip-checkmark-with-leading-icon-delay: 80ms !default;

$mdc-chip-checkmark-animation-delay: 50ms !default;
$mdc-chip-checkmark-animation-duration: 150ms !default;
$mdc-chip-width-animation-duration: 150ms !default;
$mdc-chip-opacity-animation-duration: 75ms !default;

$mdc-chip-leading-icon-margin-top: -4px !default;
$mdc-chip-leading-icon-margin-right: 4px !default;
$mdc-chip-leading-icon-margin-bottom: -4px !default;
$mdc-chip-leading-icon-margin-left: -4px !default;

$mdc-chip-trailing-icon-margin-top: 0 !default;
$mdc-chip-trailing-icon-margin-right: -4px !default;
$mdc-chip-trailing-icon-margin-bottom: 0 !default;
$mdc-chip-trailing-icon-margin-left: 4px !default;

$mdc-chip-exit-transition:
  opacity 75ms $mdc-animation-standard-curve-timing-function,
  width 150ms $mdc-animation-deceleration-curve-timing-function,
  padding 100ms linear,
  margin 100ms linear !default;
