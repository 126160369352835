﻿//COLORS - John (2019-03-01)
.facebook-color{
    color:$facebook-color;
}

.linkedin-color {
    color: $linkedin-color;
}

.fadoq-color{
    color: $fadoq-color;
}

//Point events none - John (2019-03-13)
.pointer-event-none{
    pointer-events:none;
}
//Color picker style - John (2019-03-01)
.color-picker {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
}

//HELPERS FOR EVENTS - John (2019-03-01)
.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto{
    pointer-events:auto;
}

.radius-0{
    border-radius:0 !important;
}
//FIXED WITH, USE ONLY IF NECESSARY
.w-60-px {
    width: 60px;
}
// CREATE MULTIPLE INSTANCE OF MAX-WIDTH
// CREATE MAX FROM 100 TO 2000 MW
// - John (2019-03-01)
@for $i from 1 through 20 {
    .mw-#{$i*100-px} {
        max-width: $i * 100px;
    }
}

//  CREATE MULTIPLE INSTANCE OF SHADOW
//  TO CREATE Z DIMENSION WE USE Z-INDEX
// - John (2019-03-01)
@for $i from 1 through 20 {
    .box-shadow-#{$i} {
        z-index: $i * 10;
        position: relative;
        box-shadow: $material-shadow;
    }
}
.box-shadow-0 {
    box-shadow: none;
}
.box-shadow-inner {
    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
}

.box-shadow-hover {
    pointer-events: all;
    transition: box-shadow ease-in 0.3s;

    &:hover, &:focus {
        box-shadow: $hover-shadow;
    }

    &.button:hover, &.button:focus {
        box-shadow: $hover-shadow;
    }

    & a {
        box-shadow: $hover-shadow;
        transition: background-color ease-in 0.3s;
        background-color: transparent;
    }

        & a:hover, & a:focus {
            box-shadow: $hover-shadow;
            background-color: rgba(0,0,0, 0.95);
            transition: background-color ease-in 0.3s;
        }
}
.mdc-elevation--zhover {
    pointer-events: all;
    transition: box-shadow ease-in 0.3s;

    &:hover, &:focus {
        box-shadow: $hover-shadow;
    }
}
.text-shadow {
    text-shadow: $shadow;
}


//.HR STYLING - John (2019-03-01)
.hr {
    &.hr-before {
        &:before {
            content: "";
            display: block;
            width: 100%;
            border: 0;
            height: 1px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
            margin: 1rem 0 2rem;
        }
    }

    &.hr-after {
        &:after {
            content: "";
            display: block;
            width: 100%;
            border: 0;
            height: 1px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
            margin: 2rem 0 1rem;
        }
    }
}

//  PLACEHOLDERS IMG - John (2019-03-01)

$line-color: rgb(200,200,200);
$line-width: 4px;

.img-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 400 300'><line x1='0' y1='0' x2='400' y2='300' style='stroke:"+ $line-color + ";stroke-width:"+ $line-width + "' vector-effect='non-scaling-stroke' /><line x1='0' y1='300' x2='400' y2='0' style='stroke:"+ $line-color + ";stroke-width:"+ $line-width + "' vector-effect='non-scaling-stroke' /></svg>");
    background-size: 100% 100% auto;
    background-position: center center;
}

//  CREATE 10 PLACEHOLDERS INCREASING IN SIZE 10% INCREMENT
//  FROM .IMG-BOX-1 TO .IMG-BOX-10
// - John (2019-03-01)

@for $i from 1 through 10 {
    .img-box-#{$i} {
        width: $i * 1 + 5em;
        height: $i * 1 + 5em;
        min-width: $i * 1 + 5em;
    }
}

//  DEFINE MIXINS FOR GRADIENT - John (2019-03-01)

@mixin linear-gradient($direction,$from, $GradientAnchor1, $to, $GradientAnchor2) {
    background: $to;
    background: linear-gradient($direction, $from $GradientAnchor1, $to $GradientAnchor2);
}

//  CREATE USABLE CLASS - John (2019-03-01)
%linear-gradient {
    width: 100%;
    position: absolute;
    height: 100%;
    background-size: contain;
    z-index: 3;
}

//Create linear gradient left/right direction with all theme color - John (2019-04)
@each $name, $color in $mdc-theme-colors{
    .linear-gradient--to-left-#{$name} {
        @extend %linear-gradient;

        @include linear-gradient(to left, adjust-color($color, $lightness: 50%, $alpha: -0.9), 0%, adjust-color($color, $lightness: -50%, $alpha: -0.1), 100%);
    }
    .linear-gradient--to-right-#{$name} {
        @extend %linear-gradient;
        @include linear-gradient(to right, adjust-color($color, $lightness: -50%, $alpha: -0.9), 0%, adjust-color($color, $lightness: -50%, $alpha: -0.1), 100%);
    }
}

//POSITIONING UTILITIES FOR RELATIVE => ABSOLUTE  - John (2019-03-01)
.pos {
    top: initial;
    bottom: initial;
    right: initial;
    left: initial;
}
$pos-abs: ( bottom: 0 !important, right: 0 !important, top: 0 !important, left: 0 !important );
@each $name, $move in $pos-abs {
    .pos-#{$name} {

        #{$name}: $move;
    }
}

.lh-normal{
    line-height:normal !important;
}
//EXTEND BOOTSTRAP MARGIN - John (2019-03-01)
@for $i from 6 through 10 {
    .mt-#{$i} {
        margin-top: $i + 0rem !important
    }

    .mb-#{$i} {
        margin-bottom: $i + 0rem !important
    }

    .mr-#{$i} {
        margin-right: $i + 0rem !important
    }

    .ml-#{$i} {
        margin-left: $i + 0rem !important
    }

    .my-#{$i} {
        margin-top: $i + 0rem !important;
        margin-bottom: $i + 0rem !important;
    }

    .mx-#{$i} {
        margin-left: $i + 0rem !important;
        margin-right: $i + 0rem !important;
    }

    .m-#{$i} {
        margin: $i + 0rem !important;
    }
}

.half-rounded-border{
    border-radius:18px !important;
}

//Rotate css - John (2019-03-01)

.rotate-90{
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-270 {
    transform: rotate(270deg);
}

// Shader Generation 
// Create two class from 1 to 10 as X
// .shader-black--X : Font color
// .shader-black--X-bg : BG color
// - John (2019-03-01)

@for $i from 1 through 10 {
    .shader-black--#{$i *10} {
        color: rgba(0,0,0, $i * 0.10);
    }
    .shader-light--#{$i *10} {
        color: rgba(255,255,255, $i * 0.10);
    }
    .shader-black--#{$i *10}-bg {
        background-color: rgba(0,0,0, $i * 0.10);
    }
    .shader-light--#{$i *10}-bg {
        background-color: rgba(255,255,255, $i * 0.10);
    }
}


@for $i from 1 through 20{
    .z-index-#{$i}{
        z-index: $i;
    }
}
//Create box with set where width & height = increment * 5
//Class Name : .box-5 to .box-200
//John (2018-11)
@for $i from 1 through 40 {
    .box-#{$i*5} {
        width: $i * 5px;
        min-width: $i * 5px;
        height: $i * 5px;
    }
}

//BACKGROUND-IMAGE - John (2019-03-01)
.background-image {
    background-attachment: scroll;
    background-size: cover;
    background-position: right 0;
    background-clip: border-box;
    width: 100%;
    height: 100%;
    z-index: 1;
    position:absolute;
}

%background-image-shared {
    background-clip: border-box;
    background-repeat: no-repeat;
    width: 100%;
}

.background-image-contain{
    @extend %background-image-shared;
    background-size:contain !important;


}

.background-image-cover {
    @extend %background-image-shared;
    background-size: cover !important;
}

.outline-none{
    outline:none !important;
}

.overflow-visiblë{
    overflow:visible !important;
}


.block{
    display:block;
}

.inline-block{
    display:inline-block;
}

.w-100{
    width:100%;
}







/*# sourceMappingURL=main.css.map */
