﻿//TOGGLE CONTENT DEFAULT STYLES
.toggle-content {
    display: none;
    height: 0;
    width: 0;
    overflow: hidden;
    transition: height, opacity 300ms ease-in-out;
    opacity: 0;
    will-change: height, opacity;

    &.is-visible {
        opacity: 1;
        display: block;
        height: auto;
        width: auto;
    }
}



#IndeedAdvancedSearch, .TargetedRegion, #SubRegionHolder {
    display: none;
    transition: width, height, opacity 300ms ease-in-out;
    opacity: 0;
    height: 0;
    width: 0;
    will-change: width, height, opacity;

    &.active {
        opacity: 1;
        display: inline;
        width: auto;
        height: auto;
    }
}



