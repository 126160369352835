.card__primary {
    padding: 1rem;
  }
  
  .card__title {
    margin: 0;
  }
  
  .card__subtitle {
    color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
    margin: 0;
  }
  
  .card__secondary {
    color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
    padding: 0 1rem 8px 1rem;
  }
  