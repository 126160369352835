﻿@media print {
    aside, [role="navigation"], [role="banner"], [role="alertdialog"], [role="menubar"], [role="button"], [role="status"], #map, footer, .loader-wrapper, ::-webkit-scrollbar-track, ::-webkit-scrollbar,
    ::-webkit-scrollbar-thumb {
        display: none !important;
        background:#fff !important;
    }
    .mdc-drawer-app-content, .m-p-0 {
        padding: 0 !important;
        margin:0 !important;
    }

}

/*# sourceMappingURL=main.css.map */

