﻿//
//FONT AWESOME
//
$fa-font-path: "/webfonts";
//
// BOOTSTRAP CONFIG
//
$enable-gradients : false;
$enable-caret: true !global;
$enable-rounded: true !global;
$enable-shadows: false !global;
$enable-gradients: false !global;
$enable-transitions: true !global;
$enable-hover-media-query: false !global; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !global;
$enable-print-styles: true !global;
//
//VAR COLORS
//
$white: #fff;
$gray-100: #f8f9fa !global;
$gray-200: #e9ecef !global;
$gray-300: #dee2e6 !global;
$gray-400: #ced4da !global;
$gray-500: #adb5bd !global;
$gray-600: #6c757d !global;
$gray-700: #495057 !global;
$gray-800: #343a40 !global;
$gray-900: #212529 !global;
$black: #000 !global;
$blue: #005499 !global;
$indigo: #6610f2 !global;
$purple: #4527A0 !global;
$pink: #e83e8c !global;
$red: #DB2726 !global;
$orange: #fd7e14 !global;
$yellow: #ffc107 !global;
$green: #28a745 !global;
$teal: #20c997 !global;
$cyan: #17a2b8 !global;
$humano-red: #aa3f3b !global;
$teal: #018786 !global;
$navy: #000080 !global;
//
// THEME COLORS
//
$primary: $gray-800 !global;
$secondary: $gray-600 !global;
$success: $green !global;
$info: $cyan !global;
$warning: $yellow !global;
$danger: $red !global;
$light: $gray-100 !global;
$light-gray: $gray-400 !global;
$dark: $gray-800 !global;
$facebook-color: #3B5998 !global;
$linkedin-color: #0077B5 !global;
$twitter-color: #1DA1F2 !global;
$google-color: #DB4437 !global;
$pinterest-color: #BD081C !global;
$instagram-color: #262626 !global;
$fadoq-color: #005499 !global;
$fadoq-color-secondary: #3374AA !global;
$es-color-primary: #BD2634 !global;
$es-color-secondary: #115070 !global;

//
// SHADOWS
//
$shadow: 0 1px 3px rgba(0,0,0,0.12);
$material-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 5px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
$hover-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 5px 5px 0 rgba(0,0,0,.12), 0 7px 15px -2px rgba(0,0,0,.25);
/*# sourceMappingURL=main.css.map */

$text-primary-on-light: rgba($dark,0.75);
$text-primary-on-dark: rgba($light, 0.75);

$text-secondary-on-light: rgba($dark,0.75);
$text-secondary-on-dark: rgba($light, 0.75);



$mdc-theme-primary: #212121;
$mdc-theme-secondary: #415E7E;
$mdc-theme-on-primary: rgba($light, 0.75);
$mdc-theme-on-secondary: rgba($light, 0.75);




