/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */

@import "@material/theme/mixins";

@mixin mdc-tab-indicator-surface {
  position: relative;
}

@mixin mdc-tab-indicator-underline-color($color) {
  .mdc-tab-indicator__content--underline {
    @include mdc-theme-prop(border-color, $color);
  }
}

@mixin mdc-tab-indicator-underline-height($height) {
  .mdc-tab-indicator__content--underline {
    border-top-width: $height;
  }
}

@mixin mdc-tab-indicator-underline-top-corner-radius($radius) {
  .mdc-tab-indicator__content--underline {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin mdc-tab-indicator-icon-color($color) {
  .mdc-tab-indicator__content--icon {
    @include mdc-theme-prop(color, $color);
  }
}

@mixin mdc-tab-indicator-icon-height($height) {
  .mdc-tab-indicator__content--icon {
    height: $height;
    font-size: $height;
  }
}
