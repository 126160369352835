//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/ripple/variables";
@import "@material/theme/variables";

$mdc-checkbox-mark-color: mdc-theme-prop-value(on-primary) !default;
$mdc-checkbox-border-color: rgba(mdc-theme-prop-value(on-surface), .54) !default;
$mdc-checkbox-disabled-color: rgba(mdc-theme-prop-value(on-surface), .26) !default;
$mdc-checkbox-baseline-theme-color: secondary !default;

$mdc-checkbox-touch-area: 40px !default;
$mdc-checkbox-size: 18px !default;
$mdc-checkbox-ui-pct: percentage($mdc-checkbox-size / $mdc-checkbox-touch-area) !default;
$mdc-checkbox-mark-stroke-size: 2/15 * $mdc-checkbox-size !default;
$mdc-checkbox-border-width: 2px !default;
$mdc-checkbox-transition-duration: 90ms !default;
$mdc-checkbox-item-spacing: 4px !default;
$mdc-checkbox-focus-indicator-opacity: map-get($mdc-ripple-dark-ink-opacities, focus) !default;

// Manual calculation done on SVG
$mdc-checkbox-mark-path-length_: 29.7833385 !default;
$mdc-checkbox-indeterminate-checked-easing-function_: cubic-bezier(.14, 0, 0, 1) !default;
