//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/theme/variables"; // for mdc-theme-accessible-ink-color
@import "./variables";

//
// Public
//

@mixin mdc-toolbar-ink-color($color) {
  @include mdc-theme-prop(color, $color);
}

@mixin mdc-toolbar-fill-color($color) {
  @include mdc-theme-prop(background-color, $color);
}

@mixin mdc-toolbar-fill-color-accessible($color) {
  @include mdc-toolbar-fill-color($color);
  @include mdc-toolbar-ink-color(mdc-theme-accessible-ink-color($color));

  .mdc-toolbar__icon {
    @include mdc-toolbar-icon-ink-color(mdc-theme-accessible-ink-color($color));
  }
}

@mixin mdc-toolbar-icon-ink-color($color) {
  @include mdc-theme-prop(color, $color);
  @include mdc-states($color);
}

//
// Private
//

// Applies styles to the different types of icons that can exist in toolbars.
// Both .mdc-toolbar__icon and .mdc-toolbar__menu-icon share all styles except for
// horizontal padding.
@mixin mdc-toolbar-icon_() {
  @include mdc-ripple-surface;
  @include mdc-ripple-radius-unbounded;

  // mdc-states is included within the mdc-toolbar-icon-ink-color mixin

  display: flex;
  position: relative;
  align-items: start;
  justify-content: center;
  box-sizing: border-box;
  width: $mdc-toolbar-icon-size + $mdc-toolbar-icon-padding * 2;
  height: $mdc-toolbar-icon-size + $mdc-toolbar-icon-padding * 2;
  padding: $mdc-toolbar-icon-padding;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
