//TODO : FALLBACK SYSTEM (JOHN - 2018/11)
@import "partials/reset";
// IMPORT VARIABLE
//
@import "partials/color-palettes";
@import "variable";

//
// LOAD THEME COLORS INTO LIST (JOHN - 2018/11)
//
//Theme background
//$md-theme-colors--bg__map: ('mdc-theme--primary-bg':$mdc-theme-primary, 'mdc-theme--secondary-bg':$mdc-theme-secondary);
$mdc-theme-primary: #6200ee !default; // baseline purple, 500 tone
$mdc-theme-on-primary: $light !global;

// The $mdc-theme-accent variable is DEPRECATED - it exists purely for backward compatibility.
// The $mdc-theme-secondary* variables should be used for all new projects.
$mdc-theme-secondary: #018786 !default;
$mdc-theme-on-secondary: $light !global;
$mdc-theme-background: #fff !default; // White

$mdc-theme-surface: $material-color-blue-50 !default;
$mdc-theme-on-surface: $dark !global;

$mdc-theme-error: #b00020 !default;
$mdc-theme-on-error: $light !global;

$mdc-theme-light: $material-color-grey-200;
$mdc-theme-dark: $material-color-grey-900;

//This keyvalue array is used in order to create the theme selector
//(Name, ColorToBind)
//It create parent class .mdc-theme--on-<Name>
//Use those to create zone of inteligent Styles and theme MDC
$mdc-theme-colors: ( primary: $mdc-theme-primary, secondary: $mdc-theme-secondary, light: $mdc-theme-light, dark: $mdc-theme-dark, background: $mdc-theme-background, surface: $mdc-theme-surface );

//
// IMPORT THIRD PARTY LIBRARY
//

//
// MATERIAL DESIGN
//

@import './../../node_modules/material-components-web/material-components-web';
@import 'partials/mdc-styles';

//
// PARTIALS
//

@import 'partials/spacer';
@import 'partials/utilitie';
@import 'partials/animate';


//The theme constructor should be loaded at the end
//Avoid overiding style by accident
//Also give access to Sass Variables


//IE11 FIX
@import 'partials/ie-fix';

//Media query @width
@import 'partials/responsive';

//Media query @print
@import 'partials/print';