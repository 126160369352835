﻿
/*
    IE11 FIXES
*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body {
        overflow-x: auto;
    }
    .mdc-theme--on-surface .join-icon{
        left:5px;
    }
    .mdc-menu-absolute{
        min-width:300px;
        width:95%;
        overflow:hidden;
    }
    footer{
        min-height:200px;
    }
    .mdc-dialog__container,
    .mdc-dialog__surface {
        width:100%;
    }
}


