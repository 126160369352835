//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

$mdc-toolbar-row-height: 64px !default;
$mdc-toolbar-mobile-row-height: 56px !default;
$mdc-toolbar-mobile-landscape-row-height: 48px !default;

$mdc-toolbar-element-section-padding: 8px !default;
$mdc-toolbar-element-mobile-section-padding: 4px !default;

$mdc-toolbar-element-horizontal-padding-desktop: 24px !default;
$mdc-toolbar-element-horizontal-padding-mobile: 16px !default;
$mdc-toolbar-title-margin-to-menu-icon-desktop: 8px !default;
$mdc-toolbar-title-margin-to-menu-icon-mobile: 16px !default;

$mdc-toolbar-ratio-to-extend-flexible: 4 !default;

$mdc-toolbar-mobile-landscape-width-breakpoint: 959px !default;
$mdc-toolbar-mobile-breakpoint: 599px !default;

$mdc-toolbar-icon-padding: 12px !default;
$mdc-toolbar-section-horizontal-padding: 12px !default;
$mdc-toolbar-mobile-section-horizontal-padding: 4px !default;

$mdc-toolbar-icon-size: 24px !default;
