//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/ripple/mixins";
@import "@material/theme/mixins";

// Public mixins

@mixin mdc-tab-horizontal-padding($padding) {
  padding-right: $padding;
  padding-left: $padding;
}

@mixin mdc-tab-text-label-color($color) {
  .mdc-tab__text-label {
    @include mdc-theme-prop(color, $color);
  }
}

@mixin mdc-tab-icon-color($color) {
  .mdc-tab__icon {
    @include mdc-theme-prop(color, $color);

    fill: currentColor;
  }
}

@mixin mdc-tab-states-color($color) {
  .mdc-tab__ripple {
    @include mdc-states($color);
  }
}

@mixin mdc-tab-ink-color($color) {
  @include mdc-tab-icon-color($color);
  @include mdc-tab-states-color($color);
  @include mdc-tab-text-label-color($color);
}

@mixin mdc-tab-active-text-label-color($color) {
  &.mdc-tab--active {
    @include mdc-tab-text-label-color($color);
  }
}

@mixin mdc-tab-active-icon-color($color) {
  &.mdc-tab--active {
    @include mdc-tab-icon-color($color);
  }
}

@mixin mdc-tab-active-states-color($color) {
  &.mdc-tab--active {
    @include mdc-tab-states-color($color);
  }
}

@mixin mdc-tab-parent-positioning {
  position: relative;
}

@mixin mdc-tab-fixed-width($width) {
  flex: 0 1 $width;
}
