﻿.spacer-group{
    margin-bottom:20px;
}

.spacer-title:not(h1){
    margin-top: 8px;
    margin-bottom:14px;

}

h1.spacer-title {
    margin-top: 12px;
    margin-bottom: 22px;
}

.spacer-input{
    margin-bottom:12px;
}

.spacer-text{
    margin-top:6px;
    margin-bottom:3px;
}

// CONTENT SPACER
// ADJUST TO CHANGE THE TOP MARGIN OF THE FIRST ELEMENT
// JOHN (2019-21)
html .spacer-top-content {
    margin-top:2rem;


}



// CONTENT SPACER 
// ADJUST TO CHANGE THE PADDING LEFT/RIGHT FOR CONTENT
// JOHN (2019-21)
.container-fluid {
    .pa-content, .spacer-content {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .dataTables_wrapper{
        padding:0 !important;
    }
}




// CREATE MULTIPLE LIST SPACER
// FROM .spacer-inline-list-10 to .spacer-inline-list-50
// INCREMENTS BY 10
@for $i from 1 through 5 {
    .spacer-inline-list-#{$i*10} {
        & > * {
            &:nth-child(1n+2) {
                margin-left: $i * 10px !important;
            }
        }
    }
}

