//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/animation/functions";
@import "@material/elevation/mixins";
@import "@material/rtl/mixins";
@import "../mixins";
@import "../variables";

.mdc-drawer--modal {
  @include mdc-drawer-scrim-fill-color($mdc-drawer-modal-scrim-color);
  @include mdc-elevation($mdc-drawer-modal-elevation);
  @include mdc-rtl-reflexive-position(left, 0);

  display: none;
  position: fixed;

  &.mdc-drawer--open {
    display: flex;
  }
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-timing-function: $mdc-animation-standard-curve-timing-function;
  z-index: $mdc-drawer-z-index - 1;

  .mdc-drawer--open + & {
    display: block;
  }

  .mdc-drawer--animate + & {
    opacity: 0;
  }

  .mdc-drawer--opening + & {
    transition-duration: $mdc-drawer-animation-enter;
    opacity: 1;
  }

  .mdc-drawer--closing + & {
    transition-duration: $mdc-drawer-animation-exit;
    opacity: 0;
  }
}
